<template>
    <div class="list">
        <section class="list-item" v-for="item in list" :key="item.id">
            <div class="wrap">
                <aside class="collect">
                    <div class @click="handleClickCollectBtn(item, starInit(item))">
                        <img v-if="starInit(item)" :src="require('@/assets/index/collected.png')" alt />
                        <img v-else :src="require('@/assets/index/collect.png')" alt />
                    </div>
                </aside>
                <main>
                    <header>
                        <span class="pointer" @click="titleClick(item)">{{ item.title || item.resName || item.name }}</span>
                        <span class="forbidden-btn" v-if="item.isopen && item.isopen !== '1'">受限</span>
                    </header>
                    <div class="main-info fc">
                        <template v-if="item.hasOwnProperty('officeName')">
                            <span v-if="item.officeName">提供部门：{{ item.officeName }}</span>
                        </template>
                        <template v-else>
                            <span>提供部门：{{ item.idPocName || getDeepLabel(item.idPoc) || item.idPoc }}</span>
                        </template>
                        <span v-if="item.datadomain && dataDomainOptions.length">数据领域：{{ getDomainName(item.datadomain, dataDomainOptions) || item.datadomain }}</span>
                        <span v-if="item.releaseDate || item.onlineTime">发布时间：{{ formatTime(item.releaseDate) || item.onlineTime }}</span>
                        <span v-if="'visitNum' in item">访问次数：{{ item.visitNum.toString() }}</span>
                        <span v-else>访问次数：{{ item.visitCount }}</span>
                        <span v-if="item.downloadNum">下载次数：{{ item.downloadNum }}</span>
                        <span v-if="typeof(item.dataCapacity) == 'number'">数据容量：{{ item.dataCapacity }}</span>
                    </div>
                    <div class="fc">{{ item.description || item.remarks }}</div>
                    <div class="tags" v-if="item.tags && item.tags.length">
                        <section class="tag-item" v-for="tag in item.tags" :key="tag">{{ tag }}</section>
                    </div>
                    <!-- 下载 -->
                    <div v-if="item.isopen == '1' && showDownLoad">
                        <span class="load-tag" v-for="(value, index) in loadList" :key="index" @click="loadHandle(index, item)">{{ value }}</span>
                    </div>
                </main>
                <!-- 申请条件条件有需要自己更改，不需要预留 -->
                <slot name="rightBtn" v-bind:item="item"></slot>
                <!-- <div class="btn-add"></div> -->
                <!-- <div class="btn-add" v-if="item.apply">申请</div> -->
            </div>
        </section>
    </div>
</template>

<script>
import { getDeepLabel, formatTime } from '@/utils/tools'
import { mapState } from 'vuex'
export default {
    data() {
        return {
            // showForbidden:true
            loadList: ['XLS', 'CSV', 'XML', 'JSON', 'RDF']
        }
    },
    props: {
        showDownLoad: {
            type: Boolean,
            default: true
        },
        list: {
            type: Array,
            default: () => []
        },
        starList: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    computed: {
        // store取出组织机构，数据领域，资源分类数据
        ...mapState(['dataDomainOptions', 'resClassOptions'])
    },
    methods: {
        getDeepLabel,
        formatTime,
        getDomainName(el, list) {
            let elList = el.split(',')
            let arr = elList.map(item => {
                return getDeepLabel(item, list)
            })
            return arr.join(',')
        },
        handleClickCollectBtn(item, state) {
            this.$emit('starClick', item, state)
        },
        titleClick(item) {
            this.$emit('titleClick', item)
        },
        starInit(item) {
            this.starList = this.starList || []
            for (let value of this.starList) {
                if (value === item.id) {
                    return true
                }
            }
            return false
        },
        loadHandle(index, item) {
            this.$emit('loadHandle', index, item)
        }
    },
    async mounted() {
        // 数据为空时store获取数据，初始化资源提供方，数据领域等id值
        this.$store.dispatch('getData')
        // if (this.orgOptions.length == 0) {
        //     await this.$store.dispatch('getData')
        // }
    }
}
</script>

<style scoped lang="scss">
.list {
    .list-item {
        padding: 20px 0;
        border-bottom: 1px dashed #d5d5d5;
        .wrap {
            position: relative;
            display: flex;
            align-items: center;
            .collect {
                padding: 16px;
                flex-shrink: 0;
                cursor: pointer;
                > div {
                    height: 20px;
                    width: 20px;
                    img {
                        display: block;
                    }
                }
            }
            > main {
                flex: 1;
                padding: 0 16px 0 16px;
                border-left: 1px solid #d5d6e3;
                > header {
                    color: #333;
                    font-size: $fs14;
                    .forbidden-btn {
                        display: inline-block;
                        text-align: center;
                        color: #f58646;
                        font-size: 10px;
                        /* width: 20px; */
                        /* height: 10px; */
                        background-color: #fff1e9;
                        border-radius: 10px;
                        padding: 3px 10px;
                        margin-left: 12px;
                    }
                }
                .fc {
                    color: #999;
                    font-size: $fs12;
                    margin-top: 8px;
                }
                .main-info {
                    > span {
                        position: relative;
                        padding-right: 10px;
                        display: inline-block;
                        vertical-align: middle;
                        &:not(:first-of-type) {
                            padding-left: 10px;
                        }
                        &:not(:last-of-type) {
                            &::after {
                                content: '';
                                position: absolute;
                                right: 0;
                                top: 50%;
                                transform: translateY(-50%);
                                width: 1px;
                                height: 12px;
                                background: #999;
                            }
                        }
                    }
                }
                .tags {
                    margin-top: 8px;
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;
                    .tag-item {
                        color: #357df1;
                        font-size: 10px;
                        background-color: #e2f1ff;
                        border-radius: 10px;
                        height: 20px;
                        line-height: 20px;
                        padding: 0 12px;
                        margin-right: 10px;
                        text-align: center;
                    }
                }
                .load-tag {
                    display: inline-block;
                    width: 40px;
                    height: 20px;
                    background-color: #d3f1e2;
                    border-radius: 10px;
                    margin-right: 10px;
                    color: #47c488;
                    text-align: center;
                    line-height: 20px;
                    cursor: pointer;
                }
            }
            .btn-add {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                padding: 8px 18px;
                text-align: center;
                border-radius: 4px;
                font-size: $fs12;
                color: #fff;
                background-color: #357df1;
                transition: all 0.3s ease;
                cursor: pointer;
                &:hover {
                    opacity: 0.8;
                }
            }
            .btn-added {
                background-color: #e2f1ff !important;
                color: #357df1 !important;
            }
        }
    }
}
</style>
